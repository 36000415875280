import * as Actions from '../actions/types';

const initialState = {
    base_url: [],
    single_base_url: {},
};

export const baseUrlReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_BASE_URL:
            return {
                ...state,
                base_url: action.payload,
            };
        case Actions.SET_SINGLE_BASE_URL:
            return {
                ...state,
                single_base_url: action.payload,
            };
        case Actions.SET_BASE_URL_ACTION:
            return {
                ...state,
                baseUrlAction: action.action,
            };
        default:
            return state;
    }
};
