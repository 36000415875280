import * as Actions from '../actions/types';

const initialState = {
    aboutus: [],
    single_aboutus: {},
};

export const aboutUsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_ABOUTUS:
            return {
                ...state,
                aboutus: action.payload,
            };
        case Actions.SET_SINGLE_ABOUTUS:
            return {
                ...state,
                single_aboutus: action.payload,
            };
        case Actions.SET_ABOUTUS_ACTION:
            return {
                ...state,
                aboutUsAction: action.action,
            };
        default:
            return state;
    }
};
