import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import ResizeDetector from 'react-resize-detector';

import AppMain from '../AppMain';

const Main = (props) => {
    const [initialValue, setInitialValue] = useState({
        closedSmallerSidebar: false,
        width: null
    });

    useEffect(() => {

    }, [])

    const onResize = (width) => setInitialValue({ width });

    const { width } = initialValue;

    let {
        colorScheme,
        enableFixedHeader,
        enableFixedSidebar,
        enableFixedFooter,
        enableClosedSidebar,
        closedSmallerSidebar,
        enableMobileMenu,
        enablePageTabsAlt,
    } = props;
    return (
        <Fragment>
            <div className={cx(
                "app-container app-theme-" + colorScheme,
                { 'fixed-header': enableFixedHeader },
                { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                { 'fixed-footer': enableFixedFooter },
                { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                { 'sidebar-mobile-open': enableMobileMenu },
            )}>
                <AppMain />
                <ResizeDetector handleWidth onResize={onResize} />
            </div>
        </Fragment>
    )
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

});

export default withRouter(connect(mapStateToProp)(Main));