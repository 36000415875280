import { Route, Redirect } from "react-router-dom";
import React, {Suspense, lazy, Fragment} from 'react';

import {
    ToastContainer,
} from 'react-toastify';
import Loader from '../Suspense';
import { connect } from 'react-redux';

const Alpha = lazy(() => import('../../alpha'));
const Login = lazy(() => import('../../pages/login/index'));

const AppMain = (props) => {
   let auth = props?.auth;

    if (!auth.isAuthenticated) {
        return (
            <Fragment>
                <Suspense fallback={<Loader />}>
                    <Route path="/login" component={Login}/>
                </Suspense>

                <Route exact path="/" render={() => (
                    <Redirect to="/login"/>
                )}/>

                <Route path="*" render={() => (
                    <Redirect to="/login"/>
                )}/>
                
                <ToastContainer/>
            </Fragment>
        );
    } else {
        return (
            <Fragment>

                <Suspense fallback={<Loader />}>
                    <Route path="/alpha" component={Alpha}/>
                </Suspense>
                
                <Route exact path="/" render={() => (
                    <Redirect to="/alpha/dashboard"/>
                )}/>
                
                <Route exact path="/login" render={() => (
                    <Redirect to="/alpha/dashboard"/>
                )}/>

                <ToastContainer/>
            </Fragment>
        );
    }
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps)(AppMain);

// export default AppMain;