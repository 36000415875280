import * as Actions from '../actions/types';

const initialState = {
  countries: [],
  country: {},
};

export const geographyReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case Actions.SET_SINGLE_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    case Actions.SET_COUNTRY_ACTION:
      return {
        ...state,
        countryAction: action.action,
      };
    default:
      return state;
  }
};
