import * as Actions from '../actions/types';

const initialState = {
    user_journeys: [],
};

export const userJourneyReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_USER_JOURNEYS:
            return {
                ...state,
                user_journeys: action.payload,
            };
        default:
            return state;
    }
};
