import * as Actions from '../actions/types';

const initialState = {
  affiliations: [],
  affiliation: {},
};

export const affiliationReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_AFFILIATIONS:
      return {
        ...state,
        affiliations: action.payload,
      };
    case Actions.SET_SINGLE_AFFILIATION:
      return {
        ...state,
        affiliation: action.payload,
      };
    case Actions.SET_AFFILIATION_ACTION:
      return {
        ...state,
        affiliationAction: action.action,
      };
    default:
      return state;
  }
};
