import * as Actions from '../actions/types';

const initialState = {
  banners: [],
  banner: {},
};

export const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_BANNERS:
      return {
        ...state,
        banners: action.payload,
      };
    case Actions.SET_SINGLE_BANNER:
      return {
        ...state,
        banner: action.payload,
      };
    case Actions.SET_BANNER_ACTION:
      return {
        ...state,
        bannerAction: action.action,
      };
    default:
      return state;
  }
};
