import React from 'react'

export default function index() {
  return (
    <div className="loader-container">
        <div className="loader-container-inner">
            <h6 className="mt-5">
                DOCFLIQ
                <small>Please wait...</small>
            </h6>
        </div>
    </div>
  )
}
