import * as Actions from '../actions/types';

const initialState = {
  users: [],
  single_user: {},
  new_user: {},
  selected_user: '',
  loading: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_USER_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case Actions.SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case Actions.SET_SINGLE_USER:
      return {
        ...state,
        single_user: action.payload,
      };
    case Actions.SET_NEW_USER:
      return {
        ...state,
        new_user: action.payload,
      };
    case Actions.SET_SELECTED_USER:
      return {
        ...state,
        selected_user: action.payload,
      };
    case Actions.SET_USER_ACTION:
      return {
        ...state,
        userAction: action.action,
      };
    default:
      return state;
  }
};