import * as Actions from '../actions/types';

const initialState = {
  assessments: [],
  assessment: {},
};

export const assessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_ASSESSMENTS:
      return {
        ...state,
        assessments: action.payload,
      };
    case Actions.SET_SINGLE_ASSESSMENT:
      return {
        ...state,
        assessment: action.payload,
      };
    case Actions.SET_ASSESSMENT_ACTION:
      return {
        ...state,
        assessmentAction: action.action,
      };
    default:
      return state;
  }
};
