import * as Actions from '../actions/types';

const initialState = {
  newsletter_emails: [],
  newsletter_email: {},
};

export const newsletterEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_NEWSLETTER_EMAILS:
      return {
        ...state,
        newsletter_emails: action.payload,
      };
    case Actions.SET_SINGLE_NEWSLETTER_EMAIL:
      return {
        ...state,
        newsletter_email: action.payload,
      };
    case Actions.SET_NEWSLETTER_EMAIL_ACTION:
      return {
        ...state,
        newsletterEmailAction: action.action,
      };
    default:
      return state;
  }
};
