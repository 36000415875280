import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './registerServiceWorker';
import './assets/styles/styles.css';
import { HashRouter } from 'react-router-dom';
import './assets/base.css';
import './assets/style.css';
import Main from './Layout/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';

import { setCurrentUser } from './redux/actions/authActions';
import { setUpToken } from './helper/authTokenHelpers';
import jwt_decode from 'jwt-decode';

const store = configureStore();
const rootElement = document.getElementById('root');

const token = setUpToken();
if (token) {
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
}


const renderApp = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <Component />
      </HashRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

unregister();

