// Loader
export const SET_LOADER = 'SET_LOADER';
export const CLEAR_LOADER = 'CLEAR_LOADER';

// Auth
export const SET_AUTH_LOADER = 'SET_AUTH_LOADER';
export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// Home
export const SET_HOME_LOADER = 'SET_HOME_LOADER';
export const CLEAR_HOME_DATA = 'CLEAR_HOME_DATA';

// Message
export const SET_RES_SUCCESS_MSG = 'SET_RES_SUCCESS_MSG';
export const SET_RES_ERROR_MSG = 'SET_RES_ERROR_MSG';
export const CLEAR_RES_MSG = 'CLEAR_RES_MSG';

// Countries
// export const SET_COUNTRY_LOADER = 'SET_COUNTRY_LOADER';
// export const SET_COUNTRIES = 'SET_COUNTRIES';
// export const SET_STATES = 'SET_STATES';

// Users
export const SET_USER_LOADER = 'SET_USER_LOADER';
export const SET_USERS = 'SET_USERS';
export const SET_SINGLE_USER = 'SET_SINGLE_USER';
export const SET_NEW_USER = 'SET_NEW_USER';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const SET_USER_ACTION = 'SET_USER_ACTION';

// Channels
export const SET_CHANNELS = 'SET_CHANNELS';
export const SET_CHANNEL = 'SET_CHANNEL';
export const SET_CHANNEL_ACTION = 'SET_CHANNEL_ACTION';

// Videos
export const SET_VIDEOS = 'SET_VIDEOS';
export const SET_SINGLE_VIDEO = 'SET_SINGLE_VIDEO';
export const SET_VIDEO_ACTION = 'SET_VIDEO_ACTION';

// Promotion Videos
export const SET_PROMOTION_VIDEOS_LOADER = 'SET_PROMOTION_VIDEOS_LOADER';
export const SET_PROMOTION_VIDEOS = 'SET_PROMOTION_VIDEOS';
export const SET_SINGLE_PROMOTION_VIDEO = 'SET_SINGLE_PROMOTION_VIDEO';
export const SET_PROMOTION_VIDEO_ACTION = 'SET_PROMOTION_VIDEO_ACTION';

// Newsletter Emails
export const SET_NEWSLETTER_EMAILS_LOADER = 'SET_NEWSLETTER_EMAILS_LOADER';
export const SET_NEWSLETTER_EMAILS = 'SET_NEWSLETTER_EMAILS';
export const SET_SINGLE_NEWSLETTER_EMAIL = 'SET_SINGLE_NEWSLETTER_EMAIL';
export const SET_NEWSLETTER_EMAIL_ACTION = 'SET_NEWSLETTER_EMAIL_ACTION';

// Banners
export const SET_BANNERS = 'SET_BANNERS';
export const SET_SINGLE_BANNER = 'SET_SINGLE_BANNER';
export const SET_BANNER_ACTION = 'SET_BANNER_ACTION';

// Affiliations
export const SET_AFFILIATIONS = 'SET_AFFILIATIONS';
export const SET_SINGLE_AFFILIATION = 'SET_SINGLE_AFFILIATION';
export const SET_AFFILIATION_ACTION = 'SET_AFFILIATION_ACTION';

// Specialities
export const SET_SPECIALITIES = 'SET_SPECIALITIES';
export const SET_SINGLE_SPECIALITY = 'SET_SINGLE_SPECIALITY';
export const SET_SPECIALITY_ACTION = 'SET_SPECIALITY_ACTION';

// Geography
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_SINGLE_COUNTRY = 'SET_SINGLE_COUNTRY';
export const SET_COUNTRY_ACTION = 'SET_COUNTRY_ACTION';

// About us
export const SET_ABOUTUS = 'SET_ABOUTUS';
export const SET_SINGLE_ABOUTUS = 'SET_SINGLE_ABOUTUS';
export const SET_ABOUTUS_ACTION = 'SET_ABOUTUS_ACTION';

// User journeys
export const SET_USER_JOURNEYS = 'SET_USER_JOURNEYS';

// Base urls
export const SET_BASE_URL = 'SET_BASE_URL';
export const SET_SINGLE_BASE_URL = 'SET_SINGLE_BASE_URL';
export const SET_BASE_URL_ACTION = 'SET_BASE_URL_ACTION';

// Assessments
export const SET_ASSESSMENTS = 'SET_ASSESSMENTS';
export const SET_SINGLE_ASSESSMENT = 'SET_SINGLE_ASSESSMENT';
export const SET_ASSESSMENT_ACTION = 'SET_ASSESSMENT_ACTION';



// -----------------------------------------------------------------------------------------
// Theme Related Actions
export const SET_ENABLE_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE';

export const SET_ENABLE_MOBILE_MENU = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU';
export const SET_ENABLE_MOBILE_MENU_SMALL = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL';

export const SET_ENABLE_FIXED_HEADER = 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER';
export const SET_ENABLE_HEADER_SHADOW = 'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW';
export const SET_ENABLE_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW';
export const SET_ENABLE_FIXED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR';
export const SET_ENABLE_CLOSED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR';
export const SET_ENABLE_FIXED_FOOTER = 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER';

export const SET_ENABLE_PAGETITLE_ICON = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON';
export const SET_ENABLE_PAGETITLE_SUBHEADING = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING';
export const SET_ENABLE_PAGE_TABS_ALT = 'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT';

export const SET_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE';
export const SET_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_BACKGROUND_COLOR';
export const SET_COLOR_SCHEME = 'THEME_OPTIONS/SET_COLOR_SCHEME';
export const SET_BACKGROUND_IMAGE_OPACITY = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY';

export const SET_HEADER_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR';
