import * as Actions from '../actions/types';

const initialState = {
  promotion_videos: [],
  promotion_video: {},
};

export const promotionVideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_PROMOTION_VIDEOS:
      return {
        ...state,
        promotion_videos: action.payload,
      };
    case Actions.SET_SINGLE_PROMOTION_VIDEO:
      return {
        ...state,
        promotion_video: action.payload,
      };
    case Actions.SET_PROMOTION_VIDEO_ACTION:
      return {
        ...state,
        promotionVideoAction: action.action,
      };
    default:
      return state;
  }
};
