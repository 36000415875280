import ThemeOptions from './ThemeOptions';
import { authReducer } from './authReducer';
import { channelReducer } from './channelReducer';
import { userReducer } from './userReducer';
import { homeReducer } from './homeReducer';
import { messageReducer } from './messageReducer';
import { loaderReducer } from './loaderReducer';
import { promotionVideoReducer } from './promotionVideosReducer';
import { newsletterEmailReducer } from './newsletterEmailReducer';
import { videoReducer } from './videoReducer';
import { bannerReducer } from './bannerReducer';
import { affiliationReducer } from './affiliationReducer';
import { specialityReducer } from './specialityReducer';
import { geographyReducer } from './geographyReducer';
import { aboutUsReducer } from './aboutUsReducer';
import { userJourneyReducer } from './userJourneyReducer';
import { baseUrlReducer } from './baseUrlReducer';
import { assessmentReducer } from './assessmentReducer';

const reducers = {
    ThemeOptions: ThemeOptions,
    auth: authReducer,
    channel: channelReducer,
    video: videoReducer,
    geography: geographyReducer,
    user: userReducer,
    home: homeReducer,
    message: messageReducer,
    loader: loaderReducer,
    promotionVideo: promotionVideoReducer,
    newsletterEmail: newsletterEmailReducer,
    banner: bannerReducer,
    affiliation: affiliationReducer,
    speciality: specialityReducer,
    aboutus: aboutUsReducer,
    userJourney: userJourneyReducer,
    baseUrl: baseUrlReducer,
    assessment: assessmentReducer,
}

export default reducers;