import * as Actions from '../actions/types';

const initialState = {
  specialities: [],
  speciality: {},
};

export const specialityReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_SPECIALITIES:
      return {
        ...state,
        specialities: action.payload,
      };
    case Actions.SET_SINGLE_SPECIALITY:
      return {
        ...state,
        speciality: action.payload,
      };
    case Actions.SET_SPECIALITY_ACTION:
      return {
        ...state,
        specialityAction: action.action,
      };
    default:
      return state;
  }
};
