import * as Actions from '../actions/types';

const initialState = {
  videos: [],
  video: {},
};

export const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_VIDEOS:
      return {
        ...state,
        videos: action.payload,
      };
    case Actions.SET_SINGLE_VIDEO:
      return {
        ...state,
        video: action.payload,
      };
    case Actions.SET_VIDEO_ACTION:
      return {
        ...state,
        videoAction: action.action,
      };
    default:
      return state;
  }
};
