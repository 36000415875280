import * as Actions from '../actions/types';

const initialState = {
  // channels: {},
  // channel: {},
};

export const channelReducer = (state = initialState, action) => {
  switch (action.type) {

    case Actions.SET_CHANNELS:
      return {
        ...state,
        channels: action.payload,
      };
    case Actions.SET_CHANNEL:
      return {
        ...state,
        channel: action.payload,
      };

    case Actions.SET_CHANNEL_ACTION:
      return {
        ...state,
        channelAction: action.action,
      };

    default:
      return state;
  }
};
